import bpaidLogo from './images/bpaidLogoWhiteText.png';
import './App.css';
import {AnimatePresence, motion} from 'framer-motion';

function App() {
  const fadeInVariants = {
    hidden: {opacity: 0},
    visible: {opacity: 1},
  };
  const fadeInTransition = {
    duration: 2, // Adjust the duration (in seconds) as needed
  };

  return (
    <AnimatePresence>
      <main className="app">
        <section className="gradientBG" />
        <motion.section
          className="contentContainer"
          initial="hidden"
          animate="visible"
          variants={fadeInVariants}
          transition={fadeInTransition}>
          <img src={bpaidLogo} alt="Logo" className="bpaidLogo" />
          <h1 className="appTitle">coming soon...</h1>
        </motion.section>
      </main>
    </AnimatePresence>
  );
}

export default App;
